





























































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import { AuthRegister } from '@/store/types'
import LinkPrimary from '@/components/_uikit/links/LinkPrimary.vue'
import { DocsEnum } from '@/store/types/docs'
import VKIDMixin from '@/mixins/VKIDMixin'
import VKIDButton from '@/components/VKIDButton.vue'
import { clearRedirectStorage, sendMetrik } from '@/utils/functions'

@Component({
  components: {
    Autocomplete,
    ButtonArrow,
    LinkPrimary,
    TextInput,
    VKIDButton,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class RegisterForm extends Mixins(NotifyMixin, VKIDMixin) {
  private agreementPersonal = false
  private agreementConfidential = false

  private docsLink = {
    offerAntonov: DocsEnum.OFFER_ANTONOV,
    offerEytutis: DocsEnum.OFFER_EYTUTIS,
    offerMalyshev: DocsEnum.OFFER_MALYSHEV,
    offerRogovenko: DocsEnum.OFFER_ROGOVENKO,
    offerSergeeva: DocsEnum.OFFER_SERGEEVA,
    offerSharafieva: DocsEnum.OFFER_SHARAFIEVA,
    offerShvetsov: DocsEnum.OFFER_SHVETSOV,
    offerStrelkova: DocsEnum.OFFER_STRELKOVA,
    offerVolvaka: DocsEnum.OFFER_VOLVAKA,
    personal: DocsEnum.PERSONAL,
    policy: DocsEnum.POLICY,
  }

  private form: AuthRegister = {
    cityId: 0,
    email: '',
    password: '',
    passwordConfirmation: '',
    recaptchaToken: '',
  }

  private isFocusPassword = false
  private typePassword = true
  private isFocusConfirmationPassword = false
  private typeConfirmationPassword = true

  private get cities () {
    return DictionaryModule.cities
  }

  private mounted () {
    this.$nextTick(() => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render(this.$refs.captcha, {
          'error-callback': this.handleErrorCaptcha,
          callback: this.handleCaptcha,
          sitekey: process.env.VUE_APP_CAPTCHA_SITEKEY,
          size: 'invisible',
        })
      })
    })
    this.fetchVKIDData()
    if (!this.cities.length) {
      DictionaryModule.fetchCities()
        .then(() => {
          this.fetchUserCity()
        })
    } else {
      this.fetchUserCity()
    }

    const focusInput = ((this.$refs.focus as Vue).$el as HTMLElement).querySelector('input')

    if (focusInput) {
      focusInput.focus()
    }
  }

  private fetchUserCity() {
    AuthModule.fetchUserCity()
      .then(response => {
        this.form.cityId = response.id
      })
      // Если запрос на определение города упал или не смог определить город, то по-умолчанию указывается Москва (510 ид)
      .catch(() => this.form.cityId = 510)
  }

  private handleCaptcha(token: string) {
    const form: any = this.$refs.form
    this.form.recaptchaToken = token
    AuthModule.registration(this.form)
      .then(() => {
        sendMetrik('registration')
        this.$bus.$emit('socketUser')
        requestAnimationFrame(() => (form.reset()))
        this.$router.push({ name: 'profile' }).then(clearRedirectStorage)
      })
      .catch((err) => {
        this.notifyError(err)
        window.grecaptcha.reset()
      })
  }

  private handleErrorCaptcha() {
    this.notifyError('Капча недействительна, перезагрузите страницу')
  }

  @Debounce(300)
  @Bind
  private handleRegister () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          window.grecaptcha.execute()
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
