










import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import RegisterForm from '@/components/forms/auth/RegisterForm.vue'

@Component({
  components: { RegisterForm },
})
export default class Registration extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Создание личного кабинета',
    }
  }
}
